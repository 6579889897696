#nprogress .spinner {
  top: 50%;
  left: 50%;
  width: 40px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1000;
}

#nprogress .spinner-icon {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  border: solid 3px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  animation: nprogress-spinner 500ms linear infinite;
}

.company-list {
  border: 1px solid #cccccc78;
  border-radius: 10px;
  text-align: center;
  padding: 16px 20px;
  margin-bottom: 30px;
  min-height: 100px;
  max-height: 200px;
}

.company-list:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.editRadioMargin {
  margin: 0px 2px 0px 0px !important;
}

.TC {
  color: blue !important;
  margin-left: 10px;
  text-decoration: underline;
}